// Servicio para autenticar al usuario
import { CognitoUserPool, ICognitoUserPoolData, CognitoUser, AuthenticationDetails, CognitoUserSession, CognitoRefreshToken } from 'amazon-cognito-identity-js'
import { removeData } from './local-storage.service';
import { constants } from '../utils'
import { storage } from '.';
import { API_URL } from '../utils/constants';

interface AuthResult {
  needsNewPass?: boolean;
  loginSuccess?: boolean;
  session?: CognitoUserSession;
  context?: any;
}

interface Attribute {
  Name: string;
  Value: string;
}

interface UserResult {
  $metadata?: any;
  Enabled?: boolean;
  UserAttributes?: Attribute[];
  UserCreateDate?: string;
  UserLastModifiedDate?: string;
  UserStatus?: string;
  Username?: string;
  message?: string;
}

const poolData: ICognitoUserPoolData = {
  UserPoolId: 'us-east-1_XXHK2G7pd',
  ClientId: '2c5jemp612rhdv6a37onhlhgdt',
}

let user: CognitoUser;

const userPool: CognitoUserPool = new CognitoUserPool(poolData);

const login = async (email: string, password: string): Promise<AuthResult> => {
  const userData = await getUser(email);
  let adminUser = false;

  if (userData?.UserAttributes){
    userData?.UserAttributes.forEach(attribute  => {
      if (attribute.Name === "custom:role" && attribute.Value === "ADMIN")
        adminUser = true;
    });
  }
  
  user = new CognitoUser({
    Username: email,
    Pool: userPool,
  });
  
  const authDetails = new AuthenticationDetails({
    Username: email,
    Password: password,
  });

  const auth = (): Promise<AuthResult> => new Promise((resolve, reject) => {
    user.authenticateUser(authDetails, {
      onSuccess: session => { 
        if (adminUser) {
          console.log('Session: ', session);
          resolve({ loginSuccess: true, session }); 
        }
        else 
          reject(new Error("El usuario no tiene permisos para acceder"));
      },
      onFailure: err => { reject(err); },
      newPasswordRequired: context => { resolve({ needsNewPass: true, context }); },
    });
  });

  return await auth();
}

const getUser = async (email: string): Promise<UserResult | undefined> => {
  const fetchOpts: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    }
  };
  const encodedMail = email.replace('+', '%2B');
  let URL = `${API_URL}/users-management/get-by-user?email=${encodedMail}`;
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: UserResult = await fetchResponse.json();
  if (!response) return undefined
  return response;
}

const logout = (): void => {
  removeData(constants.AUTH_USERNAME_KEY);
  removeData(constants.AUTH_USER_TOKEN_KEY);
  removeData(constants.AUTH_REFRESH_TOKEN_KEY);
}

const refreshCognitoSession = async (): Promise<any> => {
  const username = storage.getData(constants.AUTH_USERNAME_KEY);
  const refreshToken = storage.getData(constants.AUTH_REFRESH_TOKEN_KEY);
  
  if (!refreshToken || !username) {
    logout();
    throw new Error('No se pudo actualizar la sesión');
  }

  user = new CognitoUser({
    Username: username,
    Pool: userPool,
  });

  const refresh = (): Promise<any> => new Promise((resolve, reject) => {
    user.refreshSession(
      new CognitoRefreshToken({ RefreshToken: refreshToken }),
      (err, session: CognitoUserSession) => {

        if (err) reject(err);

        const token = session?.getIdToken().getJwtToken();
        const refreshToken = session?.getRefreshToken()?.getToken();
        storage.saveData(constants.AUTH_USER_TOKEN_KEY, token);
        storage.saveData(constants.AUTH_REFRESH_TOKEN_KEY, refreshToken);
        resolve(true);
      }
    );
  });

  return await refresh();
}

const resetPassword = async (username: string): Promise<any> => {
  user = new CognitoUser({
    Username: username,
    Pool: userPool,
  });

  return new Promise((resolve, reject) => {
    user.forgotPassword({
      onSuccess: function(result) {
        console.log(result)
        resolve(true);
      },
      onFailure: function(err) {
        reject(err);
      }
    });
  });
}

const confirmPassword = async (username: string, verificationCode: string, newPassword: string): Promise<any> => {
  user = new CognitoUser({
    Username: username,
    Pool: userPool,
  });

  return new Promise((resolve, reject) => {
    user.confirmPassword(verificationCode, newPassword, {
      onFailure(err) {
        reject(err);
      },
      onSuccess() {
        resolve(true);
      },
    });
  });
}

const resendEmail = async (email: string): Promise<any | undefined> => {
  const fetchOpts: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({email})
  };
  let URL = `${API_URL}/users-management/resend-password-email`;
  const fetchResponse: Response = await fetch(URL, fetchOpts);
  const response: any = await fetchResponse.json();
  if (!response) return undefined
  return response;
}

export {
  login,
  logout,
  refreshCognitoSession,
  user,
  resetPassword,
  confirmPassword,
  resendEmail
}