import MainLayout from "../../common/layouts/main.layout";
import { Box, Drawer, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  CustomTabPanel,
  DialogModal,
  SimpleModal,
} from "../../common/components";
import { User } from "../../utils";
import { deleteUser, unsubscribeUser } from "../../services/users.services";
import { AdminTable, FormUser, UserTable } from "./widgets";
import { useAppDispatch } from "../../store/hooks";
import useSchools from "../../common/hooks/use-schools";
import { setSchools } from "../../store/slices/schools";

const UserScreen = () => {
  const [edition, setEdition] = useState(false);
  const [userSelected, setUserselected] = useState<User>();
  const [updatedUser, setUpdatedUser] = useState<any>();
  const [open, setOpenModal] = useState(false);
  const [textModal, setTextModal] = useState("");
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [value, setValue] = useState(0);
  const dispatch = useAppDispatch();
  const [loadSchools, loadingSchool, schools] = useSchools();

  useEffect(() => {
    loadSchools();
  }, [loadSchools]);

  useEffect(() => {
    if (schools && !loadingSchool) {
      dispatch(setSchools(schools));
    }
  }, [loadingSchool, schools, dispatch]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    event.preventDefault();
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const onAgree = async () => {
    setOpenDialog(false);
    if (userSelected?.email) {
      try {
        setLoading(true);
        if (value === 0) {
          const unsubscribeResult = await unsubscribeUser(userSelected.email);
          if (unsubscribeResult.errorCode) {
            setTextModal(unsubscribeResult.message.es);
          }
          if (unsubscribeResult.message) {
            const update = Object.assign(
              { ...userSelected },
              { name: "Anónimo", email: "anonimo@nutrento.org" }
            );
            setUpdatedUser(update);
            setTextModal(
              `Se ha removido el acceeso al usuario ${userSelected.name} de Nutrento y ahora sus datos son anónimos`
            );
          }
        }
        else if (value === 1) {
          const deleteResult = await deleteUser(userSelected.email);
          if (deleteResult.errorCode) {
            setTextModal(deleteResult.message.es);
          }
          if (deleteResult.message) {
            setUpdatedUser("delete");
            setTextModal(
              `El usuario ${userSelected.name} se ha eliminado exitosamente de Nutrento`
            );
          }
        }
        setLoading(false);
        setOpenModal(true);
      } catch (err: any) {
        console.log(err);
        setTextModal(
          "No se pudo enviar la solicitud de actualización, revisa tu conexión sss"
        );
        setLoading(false);
        setOpenModal(true);
      }
    }
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setEdition(open);
    };

  return (
    <MainLayout title="Lista Usuarios" showActions={true}>
      <SimpleModal open={open} setOpen={setOpenModal} text={textModal} />
      <DialogModal
        title="Remover usuario"
        text={textModal}
        onAgree={onAgree}
        open={openDialog}
        setOpen={setOpenDialog}
      />
      <Box sx={{ padding: "12px 0", margin: "0" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            background: "white",
            borderRadius: "4px",
          }}
        >
          <Tabs value={value} onChange={handleChange} aria-label="users">
            <Tab label="Docentes" {...a11yProps(0)} />
            <Tab label="Administradores" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <UserTable
            setEdition={setEdition}
            setOpenDialog={setOpenDialog}
            loading={loading}
            setTextModal={setTextModal}
            updatedUser={updatedUser}
            userSelected={userSelected}
            setUserselected={setUserselected}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <AdminTable
            setEdition={setEdition}
            setOpenDialog={setOpenDialog}
            loading={loading}
            setTextModal={setTextModal}
            updatedUser={updatedUser}
            userSelected={userSelected}
            setUserselected={setUserselected}
          />
        </CustomTabPanel>
      </Box>
      <Drawer anchor="right" open={edition} onClose={toggleDrawer(false)}>
        <Box sx={{ width: "250px", padding: "14px", paddingTop: "80px" }}>
          <Typography
            id="modal-update"
            variant="h6"
            component="h3"
            sx={{ marginBottom: "24px" }}
          >
            Actualizar
          </Typography>
          <FormUser
            userSelected={userSelected}
            actionDrawer={setEdition}
            setUpdatedUser={setUpdatedUser}
            setOpenModal={setOpenModal}
            setTextModal={setTextModal}
            tab={value}
          />
        </Box>
      </Drawer>
    </MainLayout>
  );
};

export default UserScreen;
